import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  branch: <Trans i18nKey="table.head.branch" defaults="Branch" />,
  code: <Trans i18nKey="table.head.code" defaults="Code" />,
  controlLevel: (
    <Trans i18nKey="table.head.controlLevel" defaults="Control level" />
  ),
  controlsBidirectional: (
    <Trans
      i18nKey="table.head.controlsBidirectional"
      defaults="Controls bidirectional"
    />
  ),
  description: (
    <Trans i18nKey="table.head.description" defaults="Description" />
  ),
  inventoryBucket: (
    <Trans i18nKey="table.head.inventoryBucket" defaults="Inventory bucket" />
  ),
  inventoryClass: (
    <Trans i18nKey="table.head.inventoryClass" defaults="Inventory class" />
  ),
  isActive: <Trans i18nKey="table.head.isActive" defaults="Is active" />,
  modelName: <Trans i18nKey="table.head.modelName" defaults="Model name" />,
  name: <Trans i18nKey="table.head.name" defaults="Name" />,
  nestingOrder: (
    <Trans i18nKey="table.head.nestingOrder" defaults="Nesting order" />
  ),
  nestingType: (
    <Trans i18nKey="table.head.nestingType" defaults="Nesting type" />
  ),
  owner: <Trans i18nKey="table.head.owner" defaults="Owner" />,
  percentage: (
    <Trans i18nKey="table.head.percentage" defaults="Percentage (%)" />
  ),
  seatAssignmentEnabled: (
    <Trans
      i18nKey="table.head.seatAssignmentEnabled"
      defaults="Seat assignment enabled"
    />
  ),
  validFrom: <Trans i18nKey="table.head.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="table.head.validTo" defaults="Valid to" />,
});
