import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  add: <Trans i18nKey="button.add" defaults="Add" />,
  addNew: <Trans i18nKey="button.addNew" defaults="Add new" />,
  delete: <Trans i18nKey="button.delete" defaults="Delete" />,
  cancel: <Trans i18nKey="button.cancel" defaults="Cancel" />,
  create: <Trans i18nKey="button.create" defaults="Create" />,
  close: <Trans i18nKey="button.close" defaults="Close" />,
  deleteSelected: (
    <Trans i18nKey="button.deleteSelected" defaults="Delete selected" />
  ),
  pinSearch: <Trans i18nKey="button.pinSearch" defaults="Pin search" />,
  resetFilters: (
    <Trans i18nKey="button.resetFilters" defaults="Reset filters" />
  ),
  resetChanges: (
    <Trans i18nKey="button.resetChanges" defaults="Reset changes" />
  ),
  save: <Trans i18nKey="button.save" defaults="Save" />,
  search: <Trans i18nKey="button.search" defaults="Search" />,
});
