import { api } from '@fleet/shared';
import { Pagination } from '@fleet/shared/dto/pagination';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  InventoryBucket,
  InventoryBucketFilter,
  InventoryBucketValues,
} from 'dto/inventory';
import qs from 'qs';

export const clearInventoryBuckets = createAction(
  'inventoryBucket/clearInventoryBuckets'
);

export const setInventoryBucket = createAction<InventoryBucket | undefined>(
  'inventoryBucket/setInventoryBucket'
);

export const setInventoryBucketFilter = createAction<
  Partial<InventoryBucketFilter>
>('inventoryModel/setInventoryClassFilter');

export const getInventoryBuckets = createAsyncThunk<
  Pagination<InventoryBucket>,
  Partial<InventoryBucketFilter> | undefined
>('inventoryBucket/getInventoryBuckets', async (filter, { dispatch }) => {
  if (filter) {
    dispatch(setInventoryBucketFilter(filter));
  }
  return (
    await api.get(
      `/buckets${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`
    )
  ).data;
});

export const getInventoryBucket = createAsyncThunk<InventoryBucket, string>(
  'inventoryBucket/getInventoryBucket',
  async (id) => (await api.get(`/buckets/${id}`)).data
);

export const createOrUpdateInventoryBucket = createAsyncThunk<
  InventoryBucket,
  Partial<InventoryBucketValues>
>(
  'inventoryBucket/createOrUpdateInventoryBucket',
  async ({ id, ...data }) =>
    (await (id ? api.put : api.post)(`/buckets${id ? `/${id}` : ''}`, data))
      .data
);

export const deleteInventoryBucket = createAsyncThunk<void, string>(
  'inventoryBucket/deleteInventoryBucket',
  async (id) => await api.delete(`/buckets/${id}`)
);
