import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  inventoryBucket: (
    <Trans i18nKey="nav.inventoryBucket" defaults="Inventory bucket" />
  ),
  newInventoryBucket: (
    <Trans i18nKey="nav.newInventoryBucket" defaults="New inventory bucket" />
  ),
  newInventoryModel: (
    <Trans i18nKey="title.newInventoryModel" defaults="New inventory model" />
  ),
});
