import { createAsyncThunk } from 'store/utils';
import {
  InventoryModel,
  InventoryModelBucket,
  InventoryModelBucketValues,
  InventoryModelFilter,
  InventoryModelValues,
} from 'dto/inventory';
import { Pagination } from '@fleet/shared/dto/pagination';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { selectCurrentInventoryModel } from 'features/inventoryModel/inventoryModelSelector';
import { api } from '@fleet/shared';

export const clearInventoryModels = createAction(
  'inventoryModel/clearInventoryModels'
);

export const getInventoryModels = createAsyncThunk<
  Pagination<InventoryModel>,
  | Partial<
      Omit<InventoryModelFilter, 'validityFrom' | 'validityTo'> & {
        validityStartFrom: string;
        validityStartTo: string;
        validityEndFrom: string;
        validityEndTo: string;
      }
    >
  | undefined
>('inventoryModel/getInventoryModels', async (filter, { dispatch }) => {
  if (filter) {
    dispatch(setInventoryModelFilter(filter));
  }

  return (
    await api.get(
      `/models${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`
    )
  ).data;
});

export const getInventoryModel = createAsyncThunk<InventoryModel, string>(
  'inventoryModel/getInventoryModel',
  async (id) => {
    const model = (await api.get<InventoryModel>(`/models/${id}`)).data;
    const { items: buckets } = (
      await api.get<{ items: Array<InventoryModelBucket> }>(
        `/models/${id}/buckets`
      )
    ).data;

    return {
      ...model,
      buckets: buckets.map(({ percentage, ...rest }) => ({
        percentage: parseFloat((percentage * 100).toPrecision(3)),
        ...rest,
      })),
    };
  }
);

export const deleteInventoryModel = createAsyncThunk<void, string>(
  'inventoryModel/deleteInventoryModel',
  async (id) => await api.delete(`/models/${id}`)
);

export const clearCurrentInventoryModel = createAction(
  'inventoryModel/clearCurrentInventoryModel'
);

export const createOrUpdateInventoryModel = createAsyncThunk<
  InventoryModel,
  Partial<InventoryModelValues>
>(
  'inventoryModel/createOrUpdateInventoryModel',
  async ({ id, ...data }) =>
    (await (id ? api.put : api.post)(`/models${id ? `/${id}` : ''}`, data)).data
);

export const createOrUpdateInventoryModelBucket = createAsyncThunk<
  InventoryModelBucket,
  Partial<InventoryModelBucketValues>
>(
  'inventoryModel/createOrUpdateInventoryModelBucket',
  async ({ id, ...data }, { getState }) => {
    const currentModel = selectCurrentInventoryModel(getState());

    if (!currentModel) {
      return;
    }

    return (
      await (id ? api.put : api.post)(
        `/models/${currentModel.id}/buckets${id ? `/${id}` : ''}`,
        data
      )
    ).data;
  }
);

export const setInventoryModelFilter = createAction<
  Partial<InventoryModelFilter>
>('inventoryModel/setInventoryModelFilter');
