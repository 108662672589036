import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  active: <Trans i18nKey="field.active" defaults="Active" />,
  activeInventoryModels: (
    <Trans
      i18nKey="field.activeInventoryModels"
      defaults="Show only active inventory models"
    />
  ),
  code: <Trans i18nKey="field.code" defaults="Code" />,
  controlLevel: <Trans i18nKey="field.controlLevel" defaults="Control level" />,
  controlsBidirectional: (
    <Trans
      i18nKey="field.controlsBidirectional"
      defaults="Controls bidirectional"
    />
  ),
  description: <Trans i18nKey="field.description" defaults="Description" />,
  inventoryClass: (
    <Trans i18nKey="field.inventoryClass" defaults="Inventory class" />
  ),
  isActive: <Trans i18nKey="field.isActive" defaults="Is active" />,
  modelName: <Trans i18nKey="field.modelName" defaults="Model name" />,
  name: <Trans i18nKey="field.name" defaults="Name" />,
  nestingType: <Trans i18nKey="field.nestingType" defaults="Nesting type" />,
  owner: <Trans i18nKey="field.owner" defaults="Owner" />,
  validFrom: <Trans i18nKey="field.validFrom" defaults="Valid from" />,
  validFromRange: (
    <Trans i18nKey="field.validFromRange" defaults="Valid from (from-to)" />
  ),
  validTo: <Trans i18nKey="field.validTo" defaults="Valid to" />,
  validToRange: (
    <Trans i18nKey="field.validToRange" defaults="Valid to (from-to)" />
  ),
});
