import { classificationReducer } from 'features/classification/classificationReducer';
import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { commonReducer } from 'features/common/commonReducer';
import { inventoryModelReducer } from 'features/inventoryModel/inventoryModelReducer';
import { InventoryBucketReducer } from 'features/inventoryBucket/inventoryBucketReducer';

export default combineReducers({
  loading: loadingReducer,
  classification: classificationReducer,
  common: commonReducer,
  inventoryBucket: InventoryBucketReducer,
  inventoryModel: inventoryModelReducer,
});
