import { api } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';
import qs from 'qs';

export const fetchInventoryClasses = async (ownerId: number) =>
  (
    await api.get<{ items: Array<Classifier> }>(
      `/business-entities/${ownerId}/inventory-classes${qs.stringify(
        { isActive: true, limit: 100 },
        { addQueryPrefix: true, skipNulls: true }
      )}`,
      {
        baseURL: process.env.REACT_APP_API_VM,
      }
    )
  ).data.items;
