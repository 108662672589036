import qs from 'qs';
import { InventoryBucket } from 'dto/inventory';
import { Pagination, PaginationParams } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';

export const fetchInventoryBuckets = async (
  params: {
    ownerId: number;
    inventoryClassId: string;
  } & Partial<PaginationParams>
) =>
  (
    await api.get<Pagination<InventoryBucket>>(
      `/buckets${qs.stringify(params, { addQueryPrefix: true })}`
    )
  ).data.items;
