import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { InventoryBucket } from 'routes/InventoryConfiguration/InventoryBucket/InventoryBucket';
import { InventoryModel } from 'routes/InventoryConfiguration/InventoryModel/InventoryModel';

export const InventoryConfiguration: FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/configuration/bucket/:action(create|edit)?/:id?"
        component={InventoryBucket}
      />
      <Route
        exact
        path="/configuration/model/:action(create|edit)?/:id?"
        component={InventoryModel}
      />
    </Switch>
  );
};
