import { createSelector, selector } from 'store/utils';

export const selectInventoryModels = selector(
  (state) => state.inventoryModel.list
);
export const inventoryModelsSelector = createSelector(selectInventoryModels);

export const selectCurrentInventoryModel = selector(
  (state) => state.inventoryModel.current
);
export const currentInventoryModelSelector = createSelector(
  selectCurrentInventoryModel
);

export const selectInventoryModelFilter = selector(
  (state) => state.inventoryModel.filter
);
export const inventoryModelFilterSelector = createSelector(
  selectInventoryModelFilter
);
