import { Pagination } from '@fleet/shared/dto/pagination';
import { createReducer } from '@reduxjs/toolkit';
import { InventoryBucket, InventoryBucketFilter } from 'dto/inventory';
import {
  clearInventoryBuckets,
  getInventoryBucket,
  getInventoryBuckets,
  setInventoryBucket,
  setInventoryBucketFilter,
} from './inventoryBucketActions';

export interface InventoryBucketState {
  list?: Pagination<InventoryBucket>;
  current?: InventoryBucket;
  filter: Partial<InventoryBucketFilter>;
}

const initialState: InventoryBucketState = {
  filter: {},
};

export const InventoryBucketReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getInventoryBuckets.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(clearInventoryBuckets, (state) => {
      state.list = undefined;
    })
    .addCase(getInventoryBucket.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(setInventoryBucket, (state, { payload }) => {
      state.current = payload;
      if (!payload) return;
    })
    .addCase(setInventoryBucketFilter, (state, action) => {
      state.filter = action.payload;
    });
});
