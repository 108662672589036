import { createReducer } from '@reduxjs/toolkit';
import { InventoryModel, InventoryModelFilter } from 'dto/inventory';
import {
  clearCurrentInventoryModel,
  clearInventoryModels,
  getInventoryModel,
  getInventoryModels,
  setInventoryModelFilter,
} from 'features/inventoryModel/inventoryModelActions';
import { Pagination } from '@fleet/shared/dto/pagination';

export interface ConfigurationState {
  current?: InventoryModel;
  list?: Pagination<InventoryModel>;
  filter: Partial<InventoryModelFilter>;
}

const initialState: ConfigurationState = {
  filter: {},
};

export const inventoryModelReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getInventoryModels.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(clearInventoryModels, (state) => {
      state.list = undefined;
    })
    .addCase(getInventoryModel.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(clearCurrentInventoryModel, (state) => {
      state.current = undefined;
    })
    .addCase(setInventoryModelFilter, (state, action) => {
      state.filter = action.payload;
    });
});
