import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransNav = createTrans({
  inventoryConfiguration: (
    <Trans
      i18nKey="nav.inventoryConfiguration"
      defaults="Inventory configuration"
    />
  ),
  inventoryClass: (
    <Trans i18nKey="nav.inventoryClass" defaults="Inventory class" />
  ),
  inventoryBucket: (
    <Trans i18nKey="nav.inventoryBucket" defaults="Inventory bucket" />
  ),
  inventoryModel: (
    <Trans i18nKey="nav.inventoryModel" defaults="Inventory model" />
  ),
});
