import { FC } from 'react';
import { Accordion, AccordionPanel } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { TransSubtitle, TransSubtitleKeys } from 'i18n/trans/subtitle';
import { useSelector } from 'store/utils';
import { currentInventoryModelSelector } from 'features/inventoryModel/inventoryModelSelector';
import { InventoryModelBucketsAccordion } from 'routes/InventoryConfiguration/InventoryModel/Accordion/InventoryModelBucketsAccordion';

const panels: Array<[TransSubtitleKeys, FC | null]> = [
  ['inventoryBuckets', InventoryModelBucketsAccordion],
];

export const InventoryModelAccordion: FC = () => {
  const currentModel = useSelector(currentInventoryModelSelector);

  if (!currentModel) {
    return null;
  }

  const getItemCount = (key: typeof panels[number][0]) => {
    switch (key) {
      case 'inventoryBuckets':
        return currentModel.buckets.length;
      default:
        return;
    }
  };

  return (
    <Accordion controlled={false}>
      {panels.map(([key, Component]) => (
        <AccordionPanel
          key={key}
          id={key}
          summary={
            <Stack direction="row" alignItems="baseline" sx={{ gap: '5px' }}>
              <Typography variant="subtitle">
                <TransSubtitle i18nKey={key} />
              </Typography>
              <Typography variant="body2">({getItemCount(key)})</Typography>
            </Stack>
          }
          disabled={!Component}
        >
          {Component ? <Component /> : <span />}
        </AccordionPanel>
      ))}
    </Accordion>
  );
};
