import { createLoadingSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import {
  createOrUpdateInventoryModel,
  createOrUpdateInventoryModelBucket,
  deleteInventoryModel,
  getInventoryModel,
  getInventoryModels,
} from 'features/inventoryModel/inventoryModelActions';
import {
  createOrUpdateInventoryBucket,
  deleteInventoryBucket,
  getInventoryBucket,
  getInventoryBuckets,
} from 'features/inventoryBucket/inventoryBucketActions';

export const appLoadingSelector = createLoadingSelector(
  getClassifications,
  getBusinessEntities
);

export const inventoryBucketLoadingSelector = createLoadingSelector(
  getInventoryBuckets,
  getInventoryBucket,
  createOrUpdateInventoryBucket,
  deleteInventoryBucket
);

export const inventoryModelLoadingSelector = createLoadingSelector(
  getInventoryModels,
  getInventoryModel,
  createOrUpdateInventoryModel,
  deleteInventoryModel,
  createOrUpdateInventoryModelBucket
);
