import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  inventoryBucketCreated: (
    <Trans
      i18nKey="alert.inventoryBucketCreated"
      defaults="Inventory bucket created"
    />
  ),
  inventoryBucketDeleted: (
    <Trans
      i18nKey="alert.inventoryBucketDeleted"
      defaults="Inventory bucket deleted"
    />
  ),
  inventoryBucketUpdated: (
    <Trans
      i18nKey="alert.inventoryBucketUpdated"
      defaults="Inventory bucket updated"
    />
  ),
  inventoryModelCreated: (
    <Trans
      i18nKey="alert.inventoryModelCreated"
      defaults="Inventory model created"
    />
  ),
  inventoryModelDeleted: (
    <Trans
      i18nKey="alert.inventoryModelDeleted"
      defaults="Inventory model deleted"
    />
  ),
  inventoryModelUpdated: (
    <Trans
      i18nKey="alert.inventoryModelUpdated"
      defaults="Inventory model updated"
    />
  ),
  inventoryModelBucketCreated: (
    <Trans
      i18nKey="alert.inventoryModelBucketCreated"
      defaults="Inventory model's bucket created"
    />
  ),
  inventoryModelBucketUpdated: (
    <Trans
      i18nKey="alert.inventoryModelBucketUpdated"
      defaults="Inventory model's bucket updated"
    />
  ),
  inventoryModelBucketDeleted: (
    <Trans
      i18nKey="alert.inventoryModelBucketDeleted"
      defaults="Inventory model's bucket(s) deleted"
    />
  ),
});
