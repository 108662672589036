import { createSelector, selector } from 'store/utils';

export const selectInventoryBuckets = selector(
  (state) => state.inventoryBucket.list
);
export const inventoryBucketsSelector = createSelector(selectInventoryBuckets);

export const selectCurrentInventoryBucket = selector(
  (state) => state.inventoryBucket.current
);
export const currentInventoryBucketSelector = createSelector(
  selectCurrentInventoryBucket
);

export const selectInventoryBucketFilter = selector(
  (state) => state.inventoryBucket.filter
);
export const inventoryBucketFilterSelector = createSelector(
  selectInventoryBucketFilter
);
